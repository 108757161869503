
/*
    Footer section JS
    Bind events for folding and unfolding links in nav menu
*/
var footerNavigationElements = document.querySelectorAll('div.idg-footer-section p');
for(var i=0; i< footerNavigationElements.length; i++){
  footerNavigationElements[i].addEventListener('click', function(){
    this.classList.toggle('unfolded')
  });
}

