/* Header section JS
   Bind events for opening and closing sidenav
   Bind events for toggling Offers dropbox in navbar
*/

var offerComponent = document.querySelector('#idg-main-navigation__title');
var hamburger_button = document.querySelector('.idg-icon-burger');
var body = document.querySelector("body");
var header = document.getElementById('header-wrapper__div');
var overlayDiv = document.querySelector('.overlay');

offerComponent.addEventListener('click', function(){
  offerComponent.classList.toggle('unfolded');
});

hamburger_button.addEventListener('click', function(){
  if ([].indexOf.call(body.classList, 'menuOpened') === -1){
    closeMenu();
  }
  else{
    openMenu();
  }
});


var openMenu = function(){
  header.classList.toggle('overlay-open');
  body.classList.toggle('menuOpened');

};

var closeMenu = function() {
  header.classList.toggle('overlay-open');
  body.classList.toggle('menuOpened');
};


overlayDiv.addEventListener('click', function(){
  closeMenu();
});
